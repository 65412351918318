<template>
  <div>
    <booking-main-info :booking="booking" with-services-btn/>

    <b-row v-if="orders.length">
      <b-col v-for="order in orders" :key="order.id" md="3" class="mb-2">
        <router-link :to="{name: 'showOrder', params: {orderId: order.id, bookingId: bookingId}}">
          <div class="d-flex align-items-center bg-light-white rounded p-5">
            <div class="d-flex flex-column flex-grow-1 mr-2">
              <div class="font-weight-bold text-dark-75 font-size-lg mb-1">
                {{ order.status || $t('description.no_status') }}
              </div>
              <span class="text-muted font-weight-bold">{{ order.date }}</span>
            </div>
            <span class="font-weight-bolder text-info py-1 font-size-lg">
            {{ order.full_amount ? `${order.full_amount} ${order.currency}` : 0 }}
          </span>
          </div>
        </router-link>
      </b-col>
    </b-row>
    <div v-else>
      <div class="lead text-center my-5">
        {{ $t('description.no_data') }}
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import BookingMainInfo from '@/components/bookings/BookingMainInfo';

export default {
  components: {
    BookingMainInfo
  },
  data: () => ({
    bookingId: null
  }),
  async beforeMount() {
    this.bookingId = this.$route.params.bookingId;
    await this.$store.dispatch('bookingsStore/GET_DETAILS', this.bookingId);
    await this.$store.dispatch('bookingsStore/GET_ORDERS', this.bookingId);
  },
  computed: {
    ...mapGetters({
      booking: 'bookingsStore/DETAILS',
      orders: 'bookingsStore/ORDERS'
    })
  }
}
</script>