<template>
  <b-card class="mb-5">
    <template #default>
      <b-row v-if="booking">
        <b-col cols="12" md="3">
          <div>{{ $t('label.apartment') }}</div>
          <div class="font-weight-bolder">{{ booking.apartment }}</div>
        </b-col>
        <b-col cols="12" md="3">
          <div>{{ $t('label.income_date') }}</div>
          <div class="font-weight-bolder">{{ booking.income_date }}</div>
        </b-col>
        <b-col cols="12" md="3">
          <div>{{ $t('label.exit_date') }}</div>
          <div class="font-weight-bolder">{{ booking.exit_date }}</div>
        </b-col>
        <b-col cols="12" md="3" class="text-right">
          <b-button
              v-if="withServicesBtn && !oldBooking"
              variant="light-warning"
              @click="goToServicesList"
          >
            {{ $t('btn.order_service') }}
          </b-button>
          <b-button v-if="withOrdersBtn" variant="light-success" class="orders-btn ml-3" @click="goToOrders">{{ $t('btn.show_orders') }}</b-button>
        </b-col>
      </b-row>
    </template>
  </b-card>
</template>

<script>
export default {
  props: {
    booking: {
      type: Object,
      default: () => {}
    },
    withServicesBtn: {
      type: Boolean,
      default: false
    },
    withOrdersBtn: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    oldBooking() {
      return this.booking && new Date(this.booking.exit_original_date) < new Date();
    }
  },
  methods: {
    goToServicesList() {
      this.$router.push({name: 'servicesList', query: {booking_id: this.booking.id}});
    },
    goToOrders() {
      this.$router.push({name: 'bookingOrders', params: {bookingId: this.booking.id}});
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1024px) {
  .orders-btn::v-deep {
    margin-top: 10px;
  }
}
</style>